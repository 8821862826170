<template>
  <div class="my-divider">
    <div class="line"></div>
    <div class="text" v-if="text !== '' && expanded">{{ text }}</div>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  props: {
    text: String, // Prop para el texto del Divider
    expanded: Boolean
  }
}
</script>

<style scoped>
.my-divider {
  display: flex;
  align-items: center;
  width: 100%;
}

.line {
  flex: 1;
  border-bottom: 1px solid #ccc; /* Estilo de la línea del Divider */
}

.text {
  padding: 0 10px;
  color: #777; /* Estilo del texto en el medio del Divider */
}
</style>
