<template>
  <b-navbar toggleable>
    <b-navbar-toggle
      target="nav-collapse"
      @click="isMobileMenuOpen = !isMobileMenuOpen"
      ><i class="fas fa-bars text-white"></i
    ></b-navbar-toggle>
    <b-collapse id="nav-collapse" class="pl-3 pt-3 margin-top" is-nav>
      <b-navbar-nav class="text-center text-white b-navbar overflow-auto">
        <SearchBar class="pr-3 mb-2" />
        <!-- opciones de menú -->
        <div v-for="item in iconRoute" :key="item.title">
          <b-nav-item :to="item.route">{{ item.title }} </b-nav-item>
        </div>
        <a
          v-for="item in iconRedirectP"
          :key="item.title"
          class="a-nav-link"
          :href="item.route"
          target="_blank"
        >
          {{ item.title }}
        </a>
        <hr class="hr-divider-mobile" />
        <a
          v-for="item in inconRedirectPdos"
          :key="item.title"
          class="a-nav-link"
          :href="item.route"
          target="_blank"
        >
          {{ item.title }}
        </a>
        <hr class="hr-divider-mobile" />
        <a
          v-for="item in inconRedirectPtres"
          :key="item.title"
          class="a-nav-link"
          :href="item.route"
          target="_blank"
        >
          {{ item.title }}
        </a>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>
<script>
import {
  ICONROUTE,
  ICONREDIRECTP,
  ICONREDIRECTPDOS,
  ICONREDIRECTPTRES,
} from "../sidebars/Data";
import SearchBar from "../custom/SearchBar.vue";

export default {
  name: "MobileSliderBar",
  components: {
    SearchBar,
  },
  props: {},
  data() {
    return {
      isMobileMenuOpen: false,
      iconRoute: ICONROUTE,
      iconRedirectP: ICONREDIRECTP,
      inconRedirectPdos: ICONREDIRECTPDOS,
      inconRedirectPtres: ICONREDIRECTPTRES,
    };
  },
  methods: {
    closeMobileMenu() {
      this.isMobileMenuOpen = false;
    },
  },
};
</script>
<style>
.navbar-light .navbar-nav .nav-link {
  color: white;
}

.b-navbar {
  max-height: 100% !important;
}

.a-nav-link {
  display: flex;
  list-style: none;
  height: 50px;
  margin-right: 18px;
  position: relative;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  color: white;
}

.margin-top {
  margin-top: 96px;
}

.overflow-auto {
  overflow: auto;
}
.hr-divider-mobile {
  border-top: 1px solid #ccc; /* Grosor y color de la línea */
  margin: 1px 0; /* Espacio entre divisiones */
  width: 90%;
}
</style>
