export const SERIES = [{
  idSerie: '0',
  episodes: '7',
  src: "https://comino.uninorte.edu.co/NetflixUninorte/assets/s1e1_protagonistas_uninorte.jpg",
  text: "Sabemos que estás próximo a tomar una de las decisiones más importantes para tu futuro: tu carrera profesional. Aquí te contamos cómo otros jóvenes como tú se decidieron por una experiencia de vida única, en un campus inigualable y con oportunidades que solo tienen quienes que hacen parte de Uninorte.",
  title: "Uninorte: En la mejor etapa de tu vida",
  videos: [{ id: '0' }, { id: '1' }, { id: '2' }, { id: '3' }, { id: '4' }, { id: '5' }, { id: '6' }]
}, {
  idSerie: '1',
  episodes: '27',
  src: "https://comino.uninorte.edu.co/NetflixUninorte/assets/serie02_programas_academicos.jpg",
  text: "¡Descubre tu futuro académico en Uninorte! Nuestra serie de videos presenta a apasionados estudiantes de la Universidad del Norte, quienes te cuentan sobre las carreras de pregrado que brinda nuestra institución. Desde ingeniería hasta medicina, matemáticas hasta música y derecho, obtén información directamente de nuestros estudiantes para ayudarte a elegir tu camino educativo. ¡Sumérgete en el mundo de posibilidades que ofrece Uninorte!",
  title: "Conoce nuestros programas académicos",
  videos: [{ id: '7' }, { id: '8' }, { id: '9' }, { id: '10' }, { id: '11' }, { id: '12' }, { id: '13' }, { id: '14' }, { id: '15' }, { id: '16' }, { id: '17' }, { id: '18' }, { id: '19' }, { id: '20' }, { id: '21' }, { id: '22' }, { id: '23' }, { id: '24' }, { id: '25' }, { id: '26' }, { id: '27' }, { id: '28' }, { id: '29' }, { id: '30' }, { id: '31' }, { id: '32' }, { id: '33' }]
}]
