<template>
  <div>
    <Loader />
    <div class="layout-container" style="display: flex">
      <NavHeader
        :userprofile="photoUser"
        :homeURL="{ name: 'landing-page.landing-page' }"
        :logo="logo"
        :isSmallScreen="isSmallScreen"
      >
        <template slot="responsiveRight">
          <ul
            v-if="authUser.auth"
            class="d-flex align-items-center list-inline justify-content-end m-0"
          >
            <li class="nav-item nav-icon" v-nav-toggle>
              <a
                href="#"
                class="iq-user-dropdown search-toggle p-0 d-flex align-items-center"
              >
                <img
                  :src="userProfile"
                  class="img-fluid avatar-40 rounded-circle"
                  alt="user"
                />
              </a>
              <div class="iq-sub-dropdown iq-user-dropdown">
                <div class="iq-card shadow-none m-0">
                  <div class="iq-card-body p-0 pl-3 pr-3">
                    <router-link
                      :to="{ name: 'landing-page.manage-profile' }"
                      class="iq-sub-card setting-dropdown"
                    >
                      <div class="media align-items-center">
                        <div class="right-icon">
                          <i class="ri-file-user-line text-primary"></i>
                        </div>
                        <div class="media-body ml-3">
                          <h6 class="my-0">Mi Perfil</h6>
                        </div>
                      </div>
                    </router-link>
                    <router-link
                      :to="{ name: 'landing-page.resultados-test' }"
                      class="iq-sub-card setting-dropdown"
                    >
                      <div class="media align-items-center">
                        <div class="right-icon">
                          <i class="ri-file-user-line text-primary"></i>
                        </div>
                        <div class="media-body ml-3">
                          <h6 class="my-0">Mis Resultados</h6>
                        </div>
                      </div>
                    </router-link>
                    <a
                      href="#"
                      @click="logout"
                      class="iq-sub-card setting-dropdown mb-0"
                    >
                      <div class="media align-items-center">
                        <div class="right-icon">
                          <i class="ri-logout-circle-line text-primary"></i>
                        </div>
                        <div class="media-body ml-3">
                          <h6 class="my-0">Salir</h6>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <!--<router-link
            class="btn btn-hover"
            v-else
            :to="{ name: 'auth1.sign-in1' }"
            >Iniciar sesión</router-link
          >--se quita por petición-->
        </template>
      </NavHeader>
      <MainSideBar
        v-if="isSmallScreen"
        v-on:click.native="expandSideBar"
        :expand="expanded"
      />
      <div v-if="isSmallScreen" class="main-sidebar-shadow"></div>
      <div class="main-layout-container">
        <div class="fondo">
          <Home id="home" v-if="this.$route.meta.slider === 'true'" />
          <Encabezado v-if="this.$route.name == 'landing-page.privacypolicy'" />

          <div>
            <transition
              name="router-anim"
              :enter-active-class="`animated ${animated.enter}`"
              mode="out-in"
              :leave-active-class="`animated ${animated.exit}`"
            >
              <router-view />
            </transition>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { core } from "../config/pluginInit"
import loader from "../assets/images/logo-black-white.png"
import Footer from "../views/FrontendPages/Components/Footer/Footer"
import Home from "../views/FrontendPages/Components/Home/Home"
import NavHeader from "../components/core/navbars/FrontendNav"
import MainSideBar from "../components/core/sidebars/MainSideBar"
import Encabezado from "../views/FrontendPages/Components/Encabezado/Encabezado"
import Loader from "../components/core/Loader/Loader"
import { photoUser } from "../const/constant"
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"

export default {
  name: "BackendLayout",
  components: {
    Loader,
    Footer,
    Home,
    MainSideBar,
    NavHeader,
    Encabezado
  },

  data() {
    return {
      expanded: false,
      expandedWidth: 5,
      photoUser: "",
      animated: { enter: "fadeInUp", exit: "fadeOut" },
      userProfile: photoUser,
      onlyLogo: false,
      onlyLogoText: false,
      logo: loader,
      globalSearch: "",
      screenWidth: null
    }
  },
  computed: {
    authUser() {
      return this.$store.getters["Setting/authUserState"]
    },
    isSmallScreen() {
      return this.screenWidth && this.screenWidth > 768
    }
  },
  mounted() {
    core.index()
    this.$ga.page(this.$route.path)
    this.screenWidth = window.innerWidth
    window.addEventListener("resize", this.handleResize)
    this.userProfile = this.photoUser

    if (this.userProfile.length < 1 || this.userProfile === "null") {
      this.userProfile = photoUser
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize)
  },
  methods: {
    expandSideBar() {
      var mainLayout = document.querySelector(".main-layout-container")
      var mainSideNav = document.querySelector(".main-side-nav")
      var mainSideNavShadow = document.querySelector(".main-sidebar-shadow")

      this.expanded = !this.expanded

      if (this.expanded) {
        mainLayout.classList.add("expanded")
        mainSideNav.classList.add("expanded")
        mainSideNavShadow.classList.add("expanded")
      } else {
        mainLayout.classList.remove("expanded")
        mainSideNav.classList.remove("expanded")
        mainSideNavShadow.classList.remove("expanded")
      }
    },
    changeLogo(e) {
      this.logo = e
    },
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          console.log("signs out")
        })
        .catch((error) => {
          console.error("Error signing out:", error)
        })
      this.$store.dispatch("Setting/logout", { firebase })
      // this.$router.push({ path: "/auth/sign-in1" });
    },
    routerAnimationChange(e) {
      this.animated = e
    },
    searchVideo() {
      this.$router.push({
        path: "/search",
        query: { video: this.globalSearch }
      })
    },
    handleResize() {
      this.screenWidth = window.innerWidth
    }
  }
}
</script>
<style lang="scss">
@import url("../assets/css/custom.css");
@import url("../assets/css/developer.css");
@import "../assets/scss/frontend.scss";
</style>
<style>
root {
  --main-sidebar-width: 60px;
}

.layout-container > * {
  flex: 0 0 auto;
}

@media (min-width: 769px) {
  .main-layout-container {
    width: calc(100% - 60px);
    transition: 0.5s all;
    background: var(--iq-body-bg);
  }

  .main-side-nav,
  .main-sidebar-shadow {
    width: 60px;
    transition: 0.5s all;
  }

  .main-layout-container.expanded {
    width: calc(100% - 255px);
  }

  .main-side-nav.expanded,
  .main-sidebar-shadow.expanded {
    width: 255px;
    transition: 0.5s all;
  }
}

@media (max-width: 768px) {
  .main-layout-container {
    width: 100%;
  }

  .main-side-nav,
  .main-sidebar-shadow {
    width: 0px;
  }
}

.fondo {
  background: var(--iq-body-bg);
  padding-top: 96px !important;
}
</style>
