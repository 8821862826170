<template>
  <div class="side-bar-link">
    <div class="svg-icon">
      <svg preserveAspectRatio="none" width="100%" viewBox="0 -960 960 960" height="100%" xmlns="http://www.w3.org/2000/svg">
        <path :d="path"></path>
      </svg>
    </div>
    <p>{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: "Icon",
  props: {
    path: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>
