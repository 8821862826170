<template>
  <!-- loader Start -->
  <div id="loading" :style="style">
    <div id="loading-center"></div>
  </div>
  <!-- loader END -->
</template>
<script>
import lightLoader from "../../../assets/images/loader-circle.gif";
import { animation } from "../../../config/pluginInit";
import { mapGetters } from "vuex";
export default {
  name: "Loader",
  props: {
    dark: { type: Boolean, default: false },
  },
  watch: {
    $route: function (to, from) {
      // eslint-disable-next-line no-mixed-operators
      if (
        (to.meta.layout !== undefined && from.meta.layout === undefined) ||
        (to.meta.layout === undefined && from.meta.layout !== undefined) ||
        (to.meta.layout !== undefined && from.meta.layout !== undefined)
      ) {
        const load = document.getElementById("loading");
        animation.fadeIn(load, { duration: 150 });
        load.classList.remove("d-none");
      }
    },
  },
  computed: {
    style() {
      const width = window.innerWidth;
      let backgroundSize = "15%";

      if (width >= 768 && width < 1440) {
        backgroundSize = "10%";
      } else if (width >= 1440 && width < 1920) {
        backgroundSize = "10%";
      } else if (width >= 1920) {
        backgroundSize = "6%";
      }

      return `background: var(--iq-body-bg) url(${lightLoader}) no-repeat scroll center center;background-size: ${backgroundSize};`;
    },
    ...mapGetters({
      darkMode: "Setting/darkModeState",
    }),
  },
  mounted() {
    setTimeout(() => {
      const load = document.getElementById("loading");
      if (load) {
        load.classList.add("d-none");
      }
    }, 2000);
  },
};
</script>
