<template>
  <div class="main-side-nav">
    <div
      v-for="item in iconRoute"
      :key="item.title"
      class="side-bar-link-container"
    >
      <router-link v-if="expand" :to="item.route">
        <Icon :path="item.d" :title="item.title" />
      </router-link>
      <Icon v-else :path="item.d" :title="item.title" />
    </div>
    <div
      v-for="item in iconRedirectP"
      :key="item.title"
      class="side-bar-link-container"
    >
      <a
        v-if="expand"
        :href="item.route"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon :path="item.d" :title="item.title" />
      </a>
      <Icon v-else :path="item.d" :title="item.title" />
    </div>
    <MyDivider :text="'Información para'" :expanded="expand" />
    <div
      v-for="(item, index) in inconRedirectPdos"
      :key="item.title"
      class="side-bar-link-container"
    >
      <a
        v-if="expand"
        :href="item.route"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon :path="item.d" :title="item.title" />
      </a>
      <Icon v-else :path="item.d" :title="item.title" />
      <hr class="hr-divider" v-if="index === inconRedirectPdos.length - 1" />
    </div>
    <div
      v-for="item in inconRedirectPtres"
      :key="item.title"
      class="side-bar-link-container"
    >
      <a
        v-if="expand"
        :href="item.route"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon :path="item.d" :title="item.title" />
      </a>
      <Icon v-else :path="item.d" :title="item.title" />
    </div>
    <div
      v-for="item in iconDisabled"
      :key="item.title"
      class="side-bar-link-container"
      style="opacity: 50%; pointer-events: none"
    >
      <Icon :path="item.d" :title="item.title" />
    </div>
  </div>
</template>
<script>
import { core } from "../../../config/pluginInit"
import Icon from "./Icons"
import MyDivider from "./Divider.vue"
import {
  ICONROUTE,
  ICONREDIRECTP,
  ICONDISABLE,
  ICONREDIRECTPDOS,
  ICONREDIRECTPTRES
} from "./Data"

export default {
  name: "MainSideBar",
  data() {
    return {
      iconRoute: ICONROUTE,
      iconRedirectP: ICONREDIRECTP,
      iconDisabled: ICONDISABLE,
      inconRedirectPdos: ICONREDIRECTPDOS,
      inconRedirectPtres: ICONREDIRECTPTRES
    }
  },
  components: {
    Icon,
    MyDivider
  },
  props: {
    expand: { type: Boolean }
  },
  mounted() {
    core.index()
  },
  setup() {},
  methods: {}
}
</script>
<style>
.main-side-nav {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  width: 10%;
  height: 100vh;
  transition: 0.5s all;
  padding-top: 6rem;
  cursor: pointer;
}

.navbar-toggler-icon {
  width: 100%;
  height: 70px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYAgMAAACdGdVrAAAACVBMVEUAAAD///////9zeKVjAAAAAnRSTlMAuLMp9oYAAAAVSURBVAjXY4CCrFVAsBJGkQpobwoAVYgWY5ERw8gAAAAASUVORK5CYII=)
    no-repeat scroll center center;
  transition: 0.5s all;
  padding: 2rem;
}

.side-bar-link-container {
  width: 100%;
  transition: 0.5s all;
  padding: 0 5px;
}

.side-bar-link-container:hover:not(.active) {
  background-color: #aa2131;
  transition: 0.5s all;
}

.side-bar-link {
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.side-bar-link-container.active .side-bar-link {
  background: #141414;
  padding: 0 5px;
  border-radius: 20px 0 0 20px;
}

.side-bar-link svg {
  fill: rgb(247, 246, 246);
  stroke-width: 0;
  stroke: none;
  stroke-dasharray: none;
  height: 30px;
  width: auto;
  transition: 0.5s all;
}

.side-bar-link p {
  opacity: 1;
  width: 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.5s all;
  font-size: 14px;
  color: white;
}

.main-side-nav.expanded .side-bar-link p {
  opacity: 1;
  width: 100%;
  margin: 0 10px;
}

.main-side-nav.expanded .navbar-toggler-icon {
  width: 100%;
  background-color: #aa2131;
}

.navbar-toggler-icon:hover {
  background-color: #aa2131;
  cursor: pointer;
}
.hr-divider {
  border-top: 1px solid #ccc; /* Grosor y color de la línea */
  margin: 1px 0; /* Espacio entre divisiones */
  width: 100%;
}
</style>
