<template>
  <div class="home-uninorte-footer-body">
    <div class="uninorte-footer-cont hidden-mobile">
      <div class="uninorte-footer-columns">
        <div class="uninorte-footer-item item1">
          <a href="https://www.uninorte.edu.co/" class="uninorte-footer-logo" target="_self">
            <img class="uninorte-logo" src="https://uninorte.edu.co/o/uninorte-theme/images/uninorte/footer_un/logo.png"
              alt="logo">
            <img class="uninorte-separator"
              src="https://uninorte.edu.co/o/uninorte-theme/images/uninorte/footer_un/separador.png" alt="separador">
          </a>
          <span class="uninorte-location">
            Km. 5 vía Puerto Colombia
            <br>
            Área Metropolitana de Barranquilla
            <br>
            Colombia - Suramérica
          </span>
          <ul class="uninorte-links">
            <li>
              <a href="https://lineaeticauninorte.ethicsglobal.com/" target="_blank">
                <img src="https://uninorte.edu.co/o/uninorte-theme/images/uninorte/footer_un/telefono.png" alt="tlf">
                <span>Línea ética</span>
              </a>
            </li>
            <li>
              <a href="https://www.uninorte.edu.co/sugerencias" target="_blank">
                <img src="https://www.uninorte.edu.co/o/uninorte-theme/images/uninorte/footer_un/mail.png" alt="mail">

                <span>Buzón de sugerencias</span>
              </a>
            </li>
            <li>
              <a href="https://www.uninorte.edu.co/campus-seguro" target="_blank">
                <img
                  src="https://www.uninorte.edu.co/documents/20123/30388920/linea_apoyo.png/1666f6fe-0e6c-1e65-0a09-03da8bf41ff0?t=1662674815865"
                  alt="linea apoyo">
                <span>Líneas de apoyo</span>
              </a>
            </li>
            <li>
              <a href="https://www.uninorte.edu.co/canales-de-atencion" target="_blank">
                <img src="https://uninorte.edu.co/o/uninorte-theme/images/uninorte/footer_un/persona.png" alt="persona">
                <span>Otros canales</span>
              </a>
            </li>
            <li>
              <a href="https://www.uninorte.edu.co/web/tesoreria" target="_blank">
                <img src="https://uninorte.edu.co/o/uninorte-theme/images/uninorte/footer_un/web.png" alt="web">
                <span>Pagos en línea</span>
              </a>
            </li>
          </ul>
          <ul class="uninorte-social">
            <li><a href="https://www.facebook.com/uninortecolombia" target="_blank"><i class="fab fa-facebook-f"></i></a>
            </li>
            <li><a href="https://www.instagram.com/uninorteco" target="_blank"><i class="fab fa-instagram"></i></a></li>
            <li><a href="https://twitter.com/uninorteco" target="_blank"><i class="fab fa-twitter"></i></a></li>
            <li><a href="https://co.linkedin.com/school/universidad-del-norte" target="_blank"><i
                  class="fab fa-linkedin-in"></i></a></li>
            <li><a href="https://www.youtube.com/c/UninorteAcad%C3%A9mico" target="_blank"><i
                  class="fab fa-youtube"></i></a></li>
            <li><a href="https://wa.me/573145905480" target="_blank"><i class="fab fa-whatsapp"></i></a></li>
          </ul>
          <small>
            <a href="https://www.uninorte.edu.co/web/soyuninorte/directorio-de-redes">Directorio de redes
            </a>
          </small>
        </div>
        <div class="uninorte-footer-item item2">
          <div class="row">
            <div class="col-lg-4 col-sm-4">
              <div class="uninorte-footer-category">
                <a class="uninorte-footer-menu-link" href="https://www.uninorte.edu.co/academia">Academia</a>
                <img class="uninorte-separator"
                  src="https://uninorte.edu.co/o/uninorte-theme/images/uninorte/footer_un/separador.png">
              </div>
              <ul class="uninorte-footer-sublist">
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/departamentos">Divisiones y departamentos</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/centros-e-institutos">Institutos y observatorios</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/biblioteca/">Biblioteca</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/cedu">Excelencia docente</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink"
                    href="https://www.uninorte.edu.co/web/centro-de-recursos-para-el-exito-estudiantil-cree">Éxito estudiantil</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/bienestar-universitario-2">Bienestar
                    Universitario</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/gestion-academica">Gestión académica</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/ecocampus">Museo Vivo</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://virtual.uninorte.edu.co/">Uninorte Virtual</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-4 col-sm-4">
              <div class="uninorte-footer-category">
                <a class="uninorte-footer-menu-link" href="https://www.uninorte.edu.co/investigacion">Investigación</a>
                <img class="uninorte-separator"
                  src="https://uninorte.edu.co/o/uninorte-theme/images/uninorte/footer_un/separador.png">
              </div>
              <ul class="uninorte-footer-sublist">
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink"
                    href="https://www.uninorte.edu.co/web/investigacion-desarrollo-e-innovacion/grupos-de-investigacion">Grupos de
                    investigación</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/revistas">Revistas científicas y seriadas</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://editorial.uninorte.edu.co/">Editorial</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/intellecta">Revista intellecta</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink"
                    href="https://www.uninorte.edu.co/web/investigacion-desarrollo-e-innovacion/semillero-de-investigadores">Semillero de
                    investigación</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/grupo-prensa/investigacion">Noticias</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-4 col-sm-4">
              <div class="uninorte-footer-category">
                <a class="uninorte-footer-menu-link" href="https://www.uninorte.edu.co/sociedad-y-empresa">Proyección, empresa y sociedad</a>
                <img class="uninorte-separator"
                  src="https://uninorte.edu.co/o/uninorte-theme/images/uninorte/footer_un/separador.png">
              </div>
              <ul class="uninorte-footer-sublist">
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/progreso-empresa-y-sociedad/">Extensión
                    universitaria</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/consultoria-y-servicios/">Consultoría y
                    servicios</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/hospital">Hospital Universidad del Norte</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/centro-de-emprendimiento">Emprende con nosotros</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/centro-cultural-cayena">Arte y cultura</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/servicios-a-la-comunidad">Servicios a la
                    comunidad</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink"
                    href="https://uninorte-csm.symplicity.com/employers/index.php">Contrata talento Uninorte</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-sm-4">
              <div class="uninorte-footer-category">
                <a class="uninorte-footer-menu-link" href="https://www.uninorte.edu.co/">Información general y legal</a>
                <img class="uninorte-separator"
                  src="https://uninorte.edu.co/o/uninorte-theme/images/uninorte/footer_un/separador.png">
              </div>
              <ul class="uninorte-footer-sublist">
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/guest/politica-de-privacidad-de-datos">Privacidad
                    de datos</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/sobre-nosotros/convivencia">Normatividad y
                    reglamentos</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink"
                    href="https://guayacan02.uninorte.edu.co/4PL1CACI0N35/normatividad_interna/upload/File/Aprob_increm_val_mat_pec_2023.pdf">Incrementos
                    de matrícula</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink"
                    href="https://guayacan.uninorte.edu.co/normatividad_interna/upload/File/ESTATUTOS_UNINORTE.pdf">Estatuto
                    general</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink"
                    href="https://uninorte.edu.co/documents/13865921/0/Resoluci%C3%B3n+Reconocimiento+de+Personer%C3%ADa+Jur%C3%ADdica+Universidad+del+Norte.pdf/c0b11e38-22b5-4828-8a6f-a4d1e5a7477b?t=1647350196746">Creación
                    y reconocimiento Personería Jurídica</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink"
                    href="https://uninorte.edu.co/documents/13865921/0/Resoluci%C3%B3n+Acredit+Institucional+n%C2%B0.++8870+de+2018.pdf/ad9c4daa-99c3-f51d-7a16-1bb886300229?t=1647350196971">Acreditación
                    institucional</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink"
                    href="https://www.uninorte.edu.co/web/guest/instrumentos-de-gestion-de-informacion">Instrumentos de gestión de la
                    información</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-4 col-sm-4">
              <div class="uninorte-footer-category">
                <a class="uninorte-footer-menu-link" href="https://www.uninorte.edu.co/web/conectados-con-el-mundo">Universidad internacional</a>
                <img class="uninorte-separator"
                  src="https://uninorte.edu.co/o/uninorte-theme/images/uninorte/footer_un/separador.png">
              </div>
              <ul class="uninorte-footer-sublist">
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink"
                    href="https://www.uninorte.edu.co/web/intercambios-internacionales/convenios-internacionales">Convenios y alianzas</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/intercambios-internacionales">Intercambios</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink"
                    href="https://www.uninorte.edu.co/web/intercambios-internacionales/estudiantesinternacionales">Estudiantes Extranjeros</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/spanish">Learn spanish with us</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink"
                    href="https://www.uninorte.edu.co/web/intercambios-internacionales/guia-visitantes-internacionales">Guía para visitantes</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/campus-ciudad">Nuestro Campus</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-4 col-sm-4">
              <div class="uninorte-footer-category">
                <a class="uninorte-footer-menu-link" href="https://www.uninorte.edu.co/gestion-administrativa-y-financiera">Gestión administrativa
                  y financiera</a>
                <img class="uninorte-separator"
                  src="https://uninorte.edu.co/o/uninorte-theme/images/uninorte/footer_un/separador.png">
              </div>
              <ul class="uninorte-footer-sublist">
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/gestion-administrativa-y-financiera/">Administración
                    universitaria</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink"
                    href="https://www.uninorte.edu.co/web/gestion-administrativa-y-financiera/indicadores">Indicadores</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/direccion-de-gestion-humana/">Gestión Humana</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/campus-ciudad">Nuestro Campus</a>
                </li>
                <li class="uninorte-footer-menu-subitem">
                  <a class="uninorte-footer-menu-sublink" href="https://www.uninorte.edu.co/web/dunord">du Nord</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uninorte-footer-cont show-mobile">
      <div class="uninorte-footer-contact">
        <div class="uninorte-footer-logo">
          <img class="uninorte-logo" src="https://uninorte.edu.co/o/uninorte-theme/images/uninorte/footer_un/logo.png" alt="log-uninorte">

          <img class="uninorte-separator" src="https://uninorte.edu.co/o/uninorte-theme/images/uninorte/footer_un/separador.png" alt="separador">

          <span class="uninorte-location">
            km.5 vía Puerto Colombia<br>
            Área Metropolitana de Barranquilla<br>
            Colombia - Suramérica
          </span>

          <ul class="uninorte-links">
            <li>
              <a href="https://lineaeticauninorte.ethicsglobal.com/" target="_blank">
                <img src="https://uninorte.edu.co/o/uninorte-theme/images/uninorte/footer_un/telefono.png" alt="telefono">
                <span>Línea ética</span>
              </a>
            </li>
            <li>
              <a href="https://www.uninorte.edu.co/sugerencias" target="_blank">
                <img src="https://www.uninorte.edu.co/o/uninorte-theme/images/uninorte/footer_un/mail.png" alt="mail">
                <span>Buzón de sugerencias</span>
              </a>
            </li>

            <li>
              <a href="https://www.uninorte.edu.co/campus-seguro">
                <img src="https://www.uninorte.edu.co/documents/20123/30388920/linea_apoyo.png/1666f6fe-0e6c-1e65-0a09-03da8bf41ff0?t=1662674815865"
                  alt="alarma">
                <span>Líneas de apoyo</span>
              </a>
            </li>

            <li>
              <a href="https://www.uninorte.edu.co/canales-de-atencion" target="_blank">
                <img src="https://uninorte.edu.co/o/uninorte-theme/images/uninorte/footer_un/persona.png" alt="persona">
                <span>Otros canales</span>
              </a>
            </li>
            <li>
              <a href="https://www.uninorte.edu.co/web/tesoreria" target="_blank">
                <img src="https://uninorte.edu.co/o/uninorte-theme/images/uninorte/footer_un/web.png" alt="pago">
                <span>Pagos en línea</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="uninorte-footer-links">
        <ul class="uninorte-footer-mobile">
          <li class="uninorte-footer-mobile-li">
            <a class="uninorte-footer-mobile-a" data-toggle="collapse" href="#academiaCollapse" role="button"
              aria-expanded="false" aria-controls="academiaCollapse">
              Academia
              <img style="width: 12px"
                src="https://uninorte.edu.co/documents/20123/35647542/flecha-hacia-abajo+%281%29.png/867822c0-a868-7593-2d27-8e714d0b91a0?t=1677689239131"
                alt="">
            </a>
            <div class="collapse" id="academiaCollapse">
              <ul id="child-item" class="uninorte-footer-mobile-sub">
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/departamentos">
                    Divisiones y departamentos
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/centros-e-institutos">
                    Institutos y observatorios
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/biblioteca/">
                    Biblioteca
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/cedu">
                    Excelencia docente
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/centro-de-recursos-para-el-exito-estudiantil-cree">
                    Éxito estudiantil
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/bienestar-universitario-2">
                    Bienestar Universitario
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/gestion-academica">
                    Gestión académica
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/ecocampus">
                    Museo Vivo
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a"
                    href="https://virtual.uninorte.edu.co/?utm_source=UniversitySite&amp;utm_id=Btn_academia-virtualidad">
                    Uninorte Virtual
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li class="uninorte-footer-mobile-li">
            <a class="uninorte-footer-mobile-a" data-toggle="collapse" href="#investigacionCollapse" role="button"
              aria-expanded="false" aria-controls="investigacionCollapse">
              Investigación
              <img style="width: 12px"
                src="https://uninorte.edu.co/documents/20123/35647542/flecha-hacia-abajo+%281%29.png/867822c0-a868-7593-2d27-8e714d0b91a0?t=1677689239131"
                alt="">
            </a>

            <div class="collapse" id="investigacionCollapse">
              <ul id="child-item" class="uninorte-footer-mobile-sub">
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/investigacion-desarrollo-e-innovacion">
                    Vicerrectoría de Investigación, Creación e Innovación
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a"
                    href="https://www.uninorte.edu.co/web/investigacion-desarrollo-e-innovacion/sistema-de-investigacion">
                    Sistema de investigación
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a"
                    href="https://www.uninorte.edu.co/web/investigacion-desarrollo-e-innovacion/grupos-de-investigacion">
                    Investigadores
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a"
                    href="https://www.uninorte.edu.co/web/investigacion-desarrollo-e-innovacion/grupos-de-investigacion">
                    Grupos de investigación
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/revistas">
                    Revistas científicas y seriadas
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://editorial.uninorte.edu.co/">
                    Editorial Universidad del Norte
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/dip/">
                    Ciencia a tu alcance
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/intellecta">
                    Revista intellecta
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a"
                    href="https://www.uninorte.edu.co/web/investigacion-desarrollo-e-innovacion/semillero-de-investigadores">
                    Semillero de investigación
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/comite-de-etica">
                    Comité de ética
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li class="uninorte-footer-mobile-li">
            <a class="uninorte-footer-mobile-a" data-toggle="collapse" href="#proyeccionCollapse" role="button"
              aria-expanded="false" aria-controls="proyeccionCollapse">
              Proyección, empresa y sociedad
              <img style="width: 12px"
                src="https://uninorte.edu.co/documents/20123/35647542/flecha-hacia-abajo+%281%29.png/867822c0-a868-7593-2d27-8e714d0b91a0?t=1677689239131"
                alt="">
            </a>

            <div class="collapse" id="proyeccionCollapse">
              <ul id="child-item" class="uninorte-footer-mobile-sub">
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/donaciones-institucionales/sobrenosotros">
                    Relaciones con empresas
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/progreso-empresa-y-sociedad/">
                    Extensión universitaria
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/consultoria-y-servicios/">
                    Consultoría y servicios
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/consultorio-juridico">
                    Consultorio Jurídico
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/hospital">
                    Hospital Universidad del Norte
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/centro-de-emprendimiento/">
                    Emprende con nosotros
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/centro-cultural-cayena">
                    Arte y cultura
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/guest/comunidad">
                    Servicios a la comunidad
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a"
                    href="https://uninorte-csm.symplicity.com/employers/index.php?signin_tab=0">
                    Contrata talento Uninorte
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li class="uninorte-footer-mobile-li">
            <a class="uninorte-footer-mobile-a" data-toggle="collapse" href="#infoCollapse" role="button"
              aria-expanded="false" aria-controls="infoCollapse">
              Información general y legal
              <img style="width: 12px"
                src="https://uninorte.edu.co/documents/20123/35647542/flecha-hacia-abajo+%281%29.png/867822c0-a868-7593-2d27-8e714d0b91a0?t=1677689239131"
                alt="">
            </a>

            <div class="collapse" id="infoCollapse">
              <ul id="child-item" class="uninorte-footer-mobile-sub" style="display: block">
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/guest/politica-de-privacidad-de-datos">
                    Privacidad de datos
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/sobre-nosotros/convivencia">
                    Normatividad y reglamentos
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a"
                    href="https://guayacan02.uninorte.edu.co/4PL1CACI0N35/normatividad_interna/upload/File/Aprob_increm_val_mat_pec_2023.pdf">
                    Incrementos de matrícula
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a"
                    href="https://guayacan.uninorte.edu.co/normatividad_interna/upload/File/ESTATUTOS_UNINORTE.pdf">
                    Estatuto general
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a"
                    href="https://uninorte.edu.co/documents/13865921/0/Resoluci%C3%B3n+Reconocimiento+de+Personer%C3%ADa+Jur%C3%ADdica+Universidad+del+Norte.pdf/c0b11e38-22b5-4828-8a6f-a4d1e5a7477b?t=1647350196746">
                    Creación y reconocimiento Personería Jurídica
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a"
                    href="https://uninorte.edu.co/documents/13865921/0/Resoluci%C3%B3n+Acredit+Institucional+n%C2%B0.++8870+de+2018.pdf/ad9c4daa-99c3-f51d-7a16-1bb886300229?t=1647350196971">
                    Acreditación institucional
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/guest/instrumentos-de-gestion-de-informacion">
                    Instrumentos de gestión de la información
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li class="uninorte-footer-mobile-li">
            <a class="uninorte-footer-mobile-a" data-toggle="collapse" href="#internacionalCollapse" role="button"
              aria-expanded="false" aria-controls="internacionalCollapse">
              Universidad Internacional
              <img style="width: 12px"
                src="https://uninorte.edu.co/documents/20123/35647542/flecha-hacia-abajo+%281%29.png/867822c0-a868-7593-2d27-8e714d0b91a0?t=1677689239131"
                alt="">
            </a>

            <div class="collapse" id="internacionalCollapse">
              <ul id="child-item" class="uninorte-footer-mobile-sub">
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a"
                    href="https://www.uninorte.edu.co/web/intercambios-internacionales/convenios-internacionales">
                    Convenios y Alianzas
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/intercambios-internacionales/">
                    Intercambios
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a"
                    href="https://www.uninorte.edu.co/web/intercambios-internacionales/estudiantesinternacionales">
                    Estudiantes Extranjeros
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a"
                    href="https://www.uninorte.edu.co/web/intercambios-internacionales/guia-visitantes-internacionales">
                    Guía para visitantes
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/spanish/">
                    Learn spanish with us
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/campus-ciudad/">
                    Nuestro Campus
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li class="uninorte-footer-mobile-li">
            <a class="uninorte-footer-mobile-a" data-toggle="collapse" href="#gestionCollapse" role="button"
              aria-expanded="false" aria-controls="gestionCollapse">
              Gestión administrativa y financiera
              <img style="width: 12px"
                src="https://uninorte.edu.co/documents/20123/35647542/flecha-hacia-abajo+%281%29.png/867822c0-a868-7593-2d27-8e714d0b91a0?t=1677689239131"
                alt="">
            </a>

            <div class="collapse" id="gestionCollapse">
              <ul id="child-item" class="uninorte-footer-mobile-sub">
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/gestion-administrativa-y-financiera/">
                    Administración universitaria
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/gestion-administrativa-y-financiera/servicios">
                    Servicios
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/gestion-administrativa-y-financiera/indicadores">
                    Indicadores
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/direccion-de-gestion-humana/">
                    Gestión Humana
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/campus-ciudad/">
                    Nuestro Campus
                  </a>
                </li>
                <li class="uninorte-footer-mobile-sub-li">
                  <a class="uninorte-footer-mobile-sub-a" href="https://www.uninorte.edu.co/web/dunord/">
                    du Nord
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>

      <div class="uninorte-footer-social">
        <ul>
          <li>
            <a href="https://www.facebook.com/uninortecolombia">
              <i class="fab fa-facebook-f"></i>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/uninorteco">
              <i class="fab fa-instagram"></i>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/uninorteco">
              <i class="fab fa-twitter"></i>
            </a>
          </li>
          <li>
            <a href="https://co.linkedin.com/school/universidad-del-norte">
              <i class="fab fa-linkedin-in"></i>
            </a>
          </li>
          <li>
            <a href="https://wa.me/573145905480">
              <i class="fab fa-youtube"></i>
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/c/UninorteAcad%C3%A9mico">
              <i class="fab fa-whatsapp"></i>
            </a>
          </li>
        </ul>
      </div>

      <span class="uninorte-footer-directory">
        <a href="https://www.uninorte.edu.co/web/soyuninorte/directorio-de-redes" target="_blank">
          Directorio de redes
        </a>
      </span>
      <small class="uninorte-footer-watch">Vigilada Mineducación</small>
    </div>
    <small class="min-education"> Vigilada Mineducación</small>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      logo: require("../../../../assets/images/logo-black-white.png")
    }
  },
  components: {},
  mounted() { }
}
</script>
<style>
.hidden-mobile {
  display: block;
}

.home-uninorte-footer-body {
  background-color: #191919;
  color: #ffffff;
  position: relative;
}

.uninorte-footer-cont {
  margin: auto;
  width: 100%;
  max-width: 1140px;
  padding: 15px 0;
  margin: auto;
  padding: 15px 0;
}

.uninorte-footer-cont.hidden-mobile {
  position: relative;
}

a {
  color: #ffffff;
}

.uninorte-footer-columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-template-columns: auto 75%;
  grid-auto-rows: auto;
  grid-gap: 100px;
  margin: 0 1rem;
}

.uninorte-footer-item.item1 .uninorte-footer-logo .uninorte-logo,
.uninorte-footer-item.item1 .uninorte-footer-logo .uninorte-separator {
  width: 210px;
  height: auto;
  object-fit: contain;
}

.uninorte-location {
  display: block;
  color: #ffffff;
  margin: 20px 0;
  font-weight: bold;
}

.hidden-desktop ul,
.uninorte-links,
.uninorte-footer-mobile-li,
.uninorte-footer-social ul,
.uninorte-footer-mobile-sub {
  list-style: none;
  padding: 0;
  margin: 0;
}

.uninorte-links li a {
  display: flex;
  align-items: center;
  margin: 10px 0;
  text-decoration: none;
}

.uninorte-links li a img {
  width: 30px;
  height: auto;
}

.uninorte-links li a span {
  color: #fff;
  text-decoration: none;
  display: block;
  margin-left: 15px;
  font-weight: 700;
  font-size: 0.95rem;
}

.uninorte-footer-item.item1 .uninorte-social {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
}

.uninorte-footer-item.item1 .uninorte-social li {
  margin: 0 10px;
}

.uninorte-footer-item.item1 .uninorte-social li:first-child {
  margin: 0 10px 0 0;
}

.uninorte-social li a img {
  width: auto !important;
  max-width: none;
  height: 28px;
}

.uninorte-social li a {
  font-size: 1.75rem;
  color: #fff;
  text-decoration: none;
}

.uninorte-footer-item.item1 small {
  display: block;
  font-size: 80%;
}

.uninorte-footer-item.item1 small a {
  color: #fff;
  display: block;
  font-weight: bold;
  font-size: 13px;
}

.uninorte-footer-item.item2>.row {
  margin: 0;
  display: flex;
}

.uninorte-footer-item.item2 .uninorte-footer-category {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: baseline;
  height: 60px;
}

.uninorte-footer-item.item2 .uninorte-footer-category .uninorte-footer-menu-link {
  color: #fff;
  text-decoration: none;
  font-weight: 800;
  font-size: 0.95rem;
}

.uninorte-footer-item.item2 .uninorte-footer-category .uninorte-separator {
  width: 150px;
  height: auto;
  object-fit: contain;
  margin: 5px 0;
  margin-left: -4px;
}

.uninorte-footer-item.item2 .uninorte-footer-sublist {
  list-style: none;
  margin: 0;
  padding: 0;
  color: #fff;
}

.uninorte-footer-item.item2 .uninorte-footer-sublist .uninorte-footer-menu-subitem .uninorte-footer-menu-sublink {
  color: #fff;
  text-decoration: none;
  max-width: 200px;
  display: block;
}

.min-education {
  position: absolute;
  right: -45px;
  color: #fff;
  font-style: italic;
  top: 50%;
  direction: inherit;
  transform: rotate(-90deg);
}

.show-mobile {
  display: none;
}

@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}

@media only screen and (min-width: 940px) and (max-width: 1300px) {
  .uninorte-footer-columns {
    grid-template-columns: auto 70%;
    grid-gap: 20px;
  }

  .uninorte-location {
    max-width: 200px;
  }
}

@media only screen and (max-width: 940px) {
  .min-education {
    display: none;
  }

  .show-mobile {
    display: block;
  }

  .hidden-mobile {
    display: none;
  }

  .uninorte-footer-contact .uninorte-footer-logo {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  .uninorte-footer-contact .uninorte-footer-logo .uninorte-logo,
  .uninorte-footer-contact .uninorte-footer-logo .uninorte-separator {
    width: 250px;
    height: auto;
    object-fit: contain;
  }

  .uninorte-footer-contact .uninorte-footer-logo .uninorte-location {
    display: block;
    color: #fff;
    margin: 20px 0;
    text-align: center;
    font-weight: 700;
  }

  .uninorte-footer-links .uninorte-footer-mobile {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin: 15px auto;
    text-align: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .uninorte-footer-links .uninorte-footer-mobile .uninorte-footer-mobile-li {
    padding: 10px 0;
  }

  .uninorte-footer-links .uninorte-footer-mobile .uninorte-footer-mobile-li .uninorte-footer-mobile-a {
    color: #fff;
    text-decoration: none;
    font-weight: 700;
  }

  .uninorte-footer-links .uninorte-footer-mobile {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin: 15px auto;
    text-align: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .uninorte-footer-links .uninorte-footer-mobile .uninorte-footer-mobile-li {
    padding: 10px 0;
  }

  .uninorte-footer-links .uninorte-footer-mobile .uninorte-footer-mobile-li .uninorte-footer-mobile-a {
    color: #fff;
    text-decoration: none;
    font-weight: 700;
  }

  /* .uninorte-footer-links
      .uninorte-footer-mobile
      .uninorte-footer-mobile-li
      .uninorte-footer-mobile-sub {
      display: none;
    } */
  .uninorte-footer-links .uninorte-footer-mobile .uninorte-footer-mobile-sub-a {
    color: #fbfbf8;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
    text-decoration: none;
    word-break: break-word;
  }

  .uninorte-footer-social {
    margin: 20px auto;
    max-width: 200px;
    max-width: 250px;
  }

  .uninorte-footer-social ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .uninorte-footer-social ul li a {
    font-size: 1.75rem;
    font-size: 1.5rem;
    color: #fff;
    text-decoration: none;
  }

  .uninorte-footer-social ul li a img {
    height: 24px;
    width: auto;
  }

  .uninorte-footer-directory {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
  }

  .uninorte-footer-directory a {
    color: #fff;
    text-decoration: none;
    font-weight: 700;
  }

  .uninorte-footer-watch {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 65%;
    font-weight: 700;
    margin: 20px 0;
    font-style: italic;
  }
}
</style>
