<template>
  <section id="home" class="iq-main-slider p-0">
    <Slick
      v-if="sliderData.length > 0"
      @init="navAnimateButton"
      @reInit="navAnimateButton"
      id="home-slider"
      class="slider m-0 p-0"
      :option="homeSliderOption"
    >
      <div
        class="slide slick-bg s-bg-1"
        v-for="(data, index) in sliderData"
        :key="index"
        :style="
          'background: url(' +
          data.src +
          '); padding: 100px 0 50px;width:100%; background-size: cover;background-position: center center; background-repeat: no-repeat; height: 100vh; position: relative; z-index: 1;'
        "
        @click="redirectToVideo(data)"
      >
        <b-container fluid class="position-relative h-100">
          <div class="slider-inner h-100">
            <b-row class="align-items-center h-100">
              <b-col xl="6" lg="12" md="12">
                <h1
                  id="title-serie"
                  class="slider-text big-title title text-uppercase"
                >
                  {{ data.title }}
                </h1>
                <div class="d-flex align-items-center">
                  <span class="badge badge-secondary p-2">HD</span>
                  <span class="ml-3">{{ data.episodes }} Capitulos</span>
                </div>
                <p>
                  {{ data.text }}
                </p>
                <div class="trending-list">
                  <div class="text-primary title tag">
                    Etiqueta:
                    <span class="text-body"
                      >Admisiones, Prospecto, Vida Universitaria</span
                    >
                  </div>
                </div>
                <div
                  v-if="data.firstEpisode"
                  class="d-flex align-items-center r-mb-2"
                >
                  <router-link
                    :to="{
                      name: 'landing-page.category-detail',
                      query: { movieId: data.firstEpisode.id },
                    }"
                    class="btn btn-hover"
                    ><i class="fa fa-play mr-2" aria-hidden="true"></i
                    >REPRODUCIR</router-link
                  >
                </div>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </div>
    </Slick>
    <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
      <symbol
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 44 44"
        width="44px"
        height="44px"
        id="circle"
        fill="none"
        stroke="currentColor"
      >
        <circle r="20" cy="22" cx="22" id="test"></circle>
      </symbol>
    </svg>
  </section>
</template>
<script>
import firebase from "firebase/app"
import "firebase/firestore"
import { SLIDER_SERIE } from "./Data"
import { SERIES } from "../../../../const/Series"

const db = firebase.firestore()

export default {
  name: "Home",
  components: {},
  data() {
    return {
      sliderData: SLIDER_SERIE,
      series: SERIES,
      homeSliderOption: {
        autoplay: true,
        autoplaySpeed: 10000,
        speed: 1000,
        lazyLoad: "progressive",
        arrows: true,
        dots: false,
        prevArrow:
          '<div class="slick-nav prev-arrow"><i></i><svg><use xlink:href="#circle"></svg></div>',
        nextArrow:
          '<div class="slick-nav next-arrow"><i></i><svg><use xlink:href="#circle"></svg></div>',
        responsive: [
          {
            breakpoint: 992,
            settings: {
              dots: true,
              arrows: false
            }
          }
        ]
      }
    }
  },
  mounted() {
    var sliderDataTemp = []
    this.searchSerie(this.sliderData, sliderDataTemp)
    this.sliderData = sliderDataTemp
  },
  methods: {
    navAnimateButton() {
      const nav = document.getElementsByClassName("slick-nav")
      Array.from(nav, (elem) => {
        elem.addEventListener("click", (e) => {
          elem.classList.add("animate")
          setTimeout(() => {
            elem.classList.remove("animate")
          }, 1600)
        })
      })
    },
    searchSerie(series, sliderDataTemp) {
      series.forEach((serie) => {
        const seriesData = SERIES.find(
          (data) => data.idSerie === serie.idSerie
        )
        if (seriesData) {
          this.findEpisodesFromSerie(seriesData).then((serie) => {
            sliderDataTemp.push(serie)
          })
        }
      })
    },
    findEpisodesFromSerie(serie) {
      return this.$store
        .dispatch("Category/getFirstVideoOfSeries", {
          db,
          firebase,
          id: serie.idSerie
        })
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            serie.firstEpisode = doc.data()
          })
          return serie
        })
    },
    redirectToVideo(data) {
      // Verifica si existe un primer episodio
      if (data.firstEpisode) {
        // Utiliza Vue Router para redirigir al usuario al video correspondiente
        this.$router.push({
          name: "landing-page.category-detail",
          query: { movieId: data.firstEpisode.id }
        })
      }
    }
  }
}
</script>
<style>
@media (min-width: 1200px) and (max-width: 1400px) {
  /* Estilos para pantallas con un ancho mínimo de 1024px */
  #title-serie {
    font-size: 65px;
  }
}
.slide {
    cursor: pointer;
  }
</style>
