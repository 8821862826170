<template>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-sm-12">
        <nav aria-label="breadcrumb" class="text-center iq-breadcrumb-two">
          <h4 class="title">ASESOR FINANCIERO</h4>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Encabezado"
}
</script>
