<template>
  <div class="search-box iq-search-bar d-search p-absolute d-md-static">
    <form action="#" class="searchbox" @submit.prevent="searchVideo">
      <input type="search" class="text search-input font-size-12" placeholder="Busca por nombre de video ..."
        v-model="globalSearch" />

      <i class="search-link ri-search-line"></i>
    </form>
    <div class="results-box" ref="results-box">
      <div v-for="(result, index) in results" :key="index" class="results-box__result">
        <router-link @click.native="cleanSearchBox" class="d-flex align-items-center" :to="{
          name: 'landing-page.category-detail',
          query: { movieId: result.id },
        }">
          <p class="result-box__result-name">{{ result.title }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import index from "../../../algolia/index"
import "instantsearch.css/themes/satellite-min.css"

export default {
  name: "SearchBar",
  data() {
    return {
      globalSearch: "",
      resultsTemp: [],
      results: [] // VARIABLE QUE CONTIENE EL ID Y EL TITULO DE EL OBJETO EN EL BUSCADOR
    }
  },
  watch: {
    globalSearch: function (value) {
      const regex = /^[a-zA-Z\u00C0-\u00FF\u00d1\u00f1]*$/
      var isValid = regex.test(value)
      if (isValid) {
        value === "" ? (this.results = "") : this.performSearch(value)
      } else {
        console.log("contiene caracteres especiales")
      }
    }
  },
  methods: {
    cleanSearchBox() {
      this.globalSearch = ""
    },
    searchVideo() {
      this.$router.push({
        path: "/search",
        query: { video: this.globalSearch }
      })
    },
    async performSearch(value) {
      this.resultsTemp = await index.search(value, {
        hitsPerPage: 5
      })
      const resultLengt = this.resultsTemp.nbHits // Numero de objetos en el array
      if (resultLengt > 0) {
        this.$refs["results-box"].style.display = "block"
        this.results = []
        for (let index = 0; index < resultLengt; index++) {
          const titleSearch = this.resultsTemp.hits[index].title
          const idSearch = this.resultsTemp.hits[index].id
          if (titleSearch) {
            this.results.push({ title: titleSearch, id: idSearch })
          }
        }
      } else {
        this.$refs["results-box"].style.display = "none"
      }
    }
  }
}
</script>
<style scoped>
.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.results-box {
  display: none;
  background: white;
  color: black;
  text-align: left;
  position: absolute;
  width: 100%;
}

.results-box__result {
  padding: 5px;
}

.result-box__result-name {
  margin: 0;
  color: black;
  transition: 0.5s all;
}

.results-box__result:hover .result-box__result-name {
  color: red;
}

.d-md-static {
  position: static;
}

.search-box {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
  z-index: 1;
  width: 100%;
}

.search-box .search-input {
  background: #2b2926;
}
</style>
