<template>
  <!-- TOP Nav Bar -->
  <header id="main-header">
    <div class="main-header">
      <div class="hamburguer-menu">
        <div v-if="!isSmallScreen"><MobileSideBar /></div>
        <div v-else><i class="navbar-toggler-icon"></i></div>
      </div>
      <div class="logo">
        <router-link class="navbar-brand" :to="homeURL">
          <img class="img-fluid logo" :src="logo" alt="uninorte"
        /></router-link>
      </div>
      <div class="search-bar" v-if="isSmallScreen">
        <SearchBar />
      </div>
      <div class="login">
        <div class="navbar-right menu-right">
         <slot name="responsiveRight" />
        </div>
      </div>
      <div class="nav-overlay"></div>
    </div>
  </header>
  <!-- TOP Nav Bar END -->
</template>
<script>
import { core, APPLOGONAME } from "../../../config/pluginInit"
import SearchBar from "../../../../src/components/core/custom/SearchBar.vue"
import MobileSideBar from "./MobileSideBar.vue"

export default {
  name: "FrontendNav",
  components: {
    SearchBar,
    MobileSideBar
  },
  props: {
    homeURL: { type: Object, default: () => ({ name: "dashboard.home-1" }) },
    logo: {
      type: String,
      default: require("../../../assets/images/logo-black-white.png")
    },
    userprofile: { type: String },
    isSmallScreen: { type: Boolean }
  },
  data() {
    return {
      appName: APPLOGONAME,
      sidebar: false,
      globalSearch: ""
    }
  },
  mounted() {
    core.index()
    document.addEventListener("click", this.closeSidebar, true)
  },
  destroyed() {
    document.removeEventListener("click", this.closeSidebar, true)
  },
  methods: {
    closeSidebar(e) {
      if (!e.target.classList.contains("navbar-toggler-icon")) {
        this.sidebar = false
        document.getElementsByTagName("body")[0].classList.remove("nav-open")
      }
    },
    openSidebar() {
      document.getElementsByTagName("body")[0].classList.add("nav-open")
      this.sidebar = true
    },
    searchVideo() {
      this.$router.push({
        path: "/search",
        query: { video: this.globalSearch }
      })
    }
  }
}
</script>
<style>
.video-search {
  width: 500px;
  max-width: 50%;
}

.video-search .searchbox {
  display: flex;
  align-items: center;
  height: 50px;
}

.video-search .searchbox .icon-container {
  background: #2b2926;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.main-header {
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.hamburguer-menu {
  width: 60px; /* Ancho fijo de 60px para el Elemento 1 */
}

.logo {
  flex: 1; /* Los otros elementos ocuparán el espacio restante */
  text-align: start;
}

.search-bar {
  flex: 2; /* Los otros elementos ocuparán el espacio restante */
  padding: 0 1rem;
}

.login {
  flex: 1; /* Los otros elementos ocuparán el espacio restante */
  padding: 0 1rem 0 1rem;
  text-align: end;
}
</style>
